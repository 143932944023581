import { AutoComplete, Empty } from 'antd';
export default function AutocompleteComponent({ value, onChange, onSearch, options, allowClear = true, placeholder = '', onDropdownVisibleChange = () => { } }) {
  return (
    <AutoComplete
      className='autocomplete_comp'
      allowClear={allowClear}
      onSearch={onSearch}
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={onChange}
      notFoundContent={<Empty />}
      defaultValue={value}
      onDropdownVisibleChange={onDropdownVisibleChange}
      description={<h3>No se encontró información</h3>}
    />
  )
}
