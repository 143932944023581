import Image from 'next/image'

function InformationCardComponent({ title, subtitle, description, image: { source, alt } }) {
  return (
    <div className='cont_information_card'>
      <div className="cont_left">
        <Image src={source} alt={alt} width={100} height={100} />
      </div>
      <div className='cont_right'>
        <p className='title'>{title}</p>
        {subtitle && <p className="subtitle">{subtitle}</p>}
        <p className='description' dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  )
}

export default InformationCardComponent