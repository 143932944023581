'use client';
import Image from 'next/image';

export default function CustomButtonComponent({
  onClick = () => { },
  textButton = 'Enviar',
  disabled = false,
  icon = null,
  iconPosition = 'start',
  backgroundColor = null,
  textColor = null
}) {
  return <button
    className='cont_custom_button'
    style={{
      flexDirection: iconPosition === 'start' ? "row" : "row-reverse",
      backgroundColor: backgroundColor ?? backgroundColor,
      color: textColor ?? textColor
    }}
    disabled={disabled}
    onClick={onClick}
  >
    {icon && <Image src={icon} alt='logo-mercadolibre' className='icon' width={100} height={100} />}
    <p
      className='text'
      style={{
        color: textColor ?? textColor
      }}
    >
      {textButton}
    </p>
  </button>;
}
